import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { RawPortableText } from '../types';
import { DEFAULT_LANG } from '../constants';

import '../fragments.ts';
import { SEOQueryData } from '../fragments';
import { EmbeddedImageSerializer, GenericLinkSerializer } from '../serializers';

import * as styles from './termos.module.scss';

interface QueryData {
  sanityWebsite: {
    seo: SEOQueryData;
  };
  sanityTerms: {
    _rawTermsDescription: RawPortableText;
    seo?: SEOQueryData;
  };
}

const TermsPage = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      sanityWebsite {
        seo {
          ...SEOWebsiteFragment
        }
      }
      sanityTerms {
        _rawTermsDescription(resolveReferences: { maxDepth: 4 })
        seo {
          ...SEOFragment
        }
      }
    }
  `);

  const { title, image, url, description } = data.sanityWebsite.seo;
  const { _rawTermsDescription, seo } = data.sanityTerms;

  return (
    <Layout>
      <SEO
        title={(seo && seo.title ? seo.title : 'Terms') + ' - ' + title}
        description={seo && seo.description ? seo.description : description}
        url={seo && seo.url ? seo.url : url}
        imageUrl={seo && seo.image ? seo.image.asset.url : image.asset.url}
        lang={DEFAULT_LANG}
      />
      <div className={styles.container}>
        <BlockContent
          className={styles.description}
          renderContainerOnSingleChild
          blocks={_rawTermsDescription}
          serializers={{
            marks: {
              genericLink: GenericLinkSerializer,
            },
            types: {
              embeddedImage: EmbeddedImageSerializer,
            },
          }}
        />
      </div>
    </Layout>
  );
};

export default TermsPage;
